<template>
  <div class="mt-16">
    <p class="mt-6 mb-0 caption">
      Copyright © 2021 ETIQUECOSAS. Todos los derechos reservados.
    </p>
    <a href="#" class="subtitle-2 text-decoration-none">www.etiquecosas.com.ar</a>
  </div>
</template>

<script>
export default {
  name: 'ArticleCopyright',

  data: () => ({
    //
  })
}
</script>

<style lang="scss" scoped>
</style>
