<template>
  <div>
    <v-row class="mt-3 mb-3">
      <v-col cols="12">
        <v-card
            elevation="0"
        >
          <v-card-title>Detalle de compra</v-card-title>
          <v-divider></v-divider>
          <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  class="text-left"
                  :md="boxInputValues.length > 0 ? '4' : null"
                  v-for="(item, i) in boxInputValues"
                  :key="i"
                >
                  <v-card-subtitle style="font-size:12px">{{item.text}}</v-card-subtitle>
                  <v-card-text style="font-size:15px">{{itemProp[item.value] ? itemProp[item.value] : itemProp.client[item.value]}}</v-card-text>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                       sm="6"
                       class="text-left"
                       md="3">
                  <v-card-subtitle style="font-size:12px">Datos de envio</v-card-subtitle>
                  <v-card-text style="font-size:15px">
                    Direccion {{itemProp.client_address}} <br>
                    Codigo postal {{itemProp.client_postcode}} <br>
                    Ciudad {{itemProp.client_city}} <br>
                    Provincia {{itemProp.client_Province}}
                  </v-card-text>
                </v-col>
              </v-row>
            </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'ViewCardDetails',
  props: {
    boxInputValues: Array,
    itemProp: Object
  },
  mounted () {
    console.log(this.itemProp)
  }
}
</script>
