<template>
  <v-row justify="center">
    <v-dialog
      v-model="controlOpenDialog"
      scrollable
      persistent
      max-width="313px"
      height="373"
    >
      <v-card>
        <v-card-title>Comentario Interno</v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="item!=null">
          <v-textarea autofocus cols="130" rows="5" v-model="item.commentary"></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray darken-1"
            text
            @click="close(false)"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="pink darken-1"
            :text="true"
            :style="'primary'"
            @click="$emit('setCommentary', { order: item, dialog: 'commentary' })"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'ComentaryDialog',
  props: {
    dialog: Boolean,
    item: { type: Object, default: () => ({ commentary: '' }) }
  },

  methods: {
    close (val) {
      this.$emit('closeDialog', 'commentary')
    }
  },

  computed: {
    controlOpenDialog: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('handleDialog', value)
      }
    }
  }
}
</script>
