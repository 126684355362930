import axios from './instance'

const configToken = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export const userLogin = async (body) => {
  try {
    const res = await axios.post('/login', body)
    return res
  } catch (err) {
    throw err.response
  }
}

export const userLogout = async (token) => {
  try {
    const res = await axios.post('/logout', null, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllUsers = async (token) => {
  try {
    const res = await axios.get('/users', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllRoles = async (token) => {
  try {
    const res = await axios.get('/roles', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const createUser = async (token, user) => {
  try {
    const res = await axios.post('/users', user, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const updateUser = async (token, user) => {
  try {
    const res = await axios.put(`/users/${user.id}`, user, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const deleteUser = async (token, id) => {
  try {
    const res = await axios.delete(`/users/${id}`, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}
