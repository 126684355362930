<template>
  <v-toolbar flat>
    <v-toolbar-title>{{tableTitle()}}</v-toolbar-title>
    <v-divider class="mx-4" inset vertical></v-divider>
    <v-spacer></v-spacer>
    <v-dialog v-model="controlOpenNew" max-width="500px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
          {{toolbarBtnText()}}
        </v-btn>
      </template>
      <slot name="newItem"></slot>
    </v-dialog>
  </v-toolbar>
</template>

<script>
export default {
  name: 'ToolbarInsideTable',
  props: {
    openNewItem: Boolean,
    tableTitle: Function,
    toolbarBtnText: Function
  },
  computed: {
    controlOpenNew: {
      get () {
        return this.openNewItem
      },
      set (value) {
        this.$emit('handleNewItem', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
