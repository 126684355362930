<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill="#9C5A90" />
    <path
      d="M5.00745 13.0949C5.00552 13.0949 5.00368 13.0949 5.00179 13.0949C4.84795 13.093 4.70473 13.0161 4.61821 12.8889C4.48155 12.6879 4.2812 12.2246 3.94236 10.4104C3.76073 9.43804 3.6242 8.52231 3.62286 8.5132C3.58462 8.25604 3.76212 8.01665 4.01928 7.97841C4.27627 7.94013 4.51583 8.11763 4.55407 8.37483C4.55537 8.38377 4.68885 9.27854 4.86644 10.2304C4.96903 10.7802 5.05677 11.1904 5.12966 11.4956C5.57352 10.6776 6.11531 9.57757 6.3538 9.0808C6.44114 8.89894 6.63457 8.79294 6.83501 8.81731C7.03535 8.84168 7.19777 8.99099 7.23897 9.18851C7.31693 9.56137 7.51889 10.3378 7.80581 11.0425C7.91163 10.0406 8.11642 8.76902 8.50683 7.96867C8.62078 7.73503 8.90263 7.638 9.13627 7.75199C9.36992 7.86599 9.46695 8.14779 9.35295 8.38143C8.73217 9.65405 8.63923 12.553 8.63833 12.5822C8.63276 12.77 8.51608 12.9364 8.34149 13.0057C8.16686 13.075 7.96782 13.0339 7.83498 12.901C7.41324 12.4793 7.0288 11.7525 6.69224 10.7409C6.6777 10.6971 6.66356 10.6538 6.64979 10.611C6.23716 11.4322 5.69474 12.4727 5.39023 12.8983C5.30173 13.0217 5.15914 13.0949 5.00745 13.0949Z"
      fill="white"
    />
    <path
      d="M12.3147 9.12251C12.1187 8.72438 11.782 8.44909 11.3421 8.3267C10.8404 8.18744 10.2968 8.37266 9.88791 8.82285C9.40253 9.35602 9.04847 10.3554 9.50993 11.5596C9.82602 12.385 10.3231 12.5364 10.6443 12.5364C10.679 12.5364 10.7114 12.5346 10.7414 12.5318C11.5963 12.4506 12.3143 11.3195 12.4526 10.5439C12.552 9.9882 12.5056 9.50987 12.3147 9.12251ZM11.5259 10.3783C11.4823 10.6208 11.3308 10.9415 11.1391 11.1952C10.9421 11.4565 10.7489 11.5854 10.6528 11.5943C10.5941 11.5999 10.4853 11.4743 10.3892 11.2229C10.196 10.7193 10.1313 9.95391 10.5843 9.45588C10.7194 9.30724 10.8765 9.22327 11.0115 9.22327C11.0387 9.22327 11.065 9.22654 11.0898 9.23359C11.5939 9.37384 11.6051 9.93331 11.5259 10.3783Z"
      fill="white"
    />
    <path
      d="M15.8119 9.12251C15.7135 8.92369 15.5803 8.75486 15.4171 8.6212C15.253 8.48755 15.0588 8.38814 14.8389 8.3267C14.3375 8.18744 13.794 8.37266 13.3847 8.82285C12.8998 9.35602 12.5457 10.3554 13.0067 11.5596C13.3228 12.385 13.8199 12.5364 14.1411 12.5364C14.1758 12.5364 14.2086 12.5346 14.2386 12.5318C15.093 12.4506 15.811 11.3195 15.9493 10.5439C16.0488 9.9882 16.0023 9.50987 15.8119 9.12251ZM15.0227 10.3783C14.9796 10.6208 14.8276 10.9415 14.6363 11.1952C14.4388 11.4565 14.2456 11.5854 14.1495 11.5943C14.0913 11.5999 13.9821 11.4743 13.886 11.2229C13.6932 10.7193 13.6285 9.95391 14.0811 9.45588C14.2166 9.30724 14.3737 9.22327 14.5083 9.22327C14.5355 9.22327 14.5617 9.22654 14.587 9.23359C15.0912 9.37384 15.1019 9.93331 15.0227 10.3783Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'WooIcon'
}
</script>

<style>
</style>
