<template>
  <v-row justify="center">
    <v-dialog
      v-model="controlOpenDialog"
      scrollable
      persistent
      max-width="313px"
      height="373"
    >
      <v-card v-if="item">
        <v-card-title>Cambiar Estado</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-radio-group
            v-model="item.id_order_status"
            column
            v-for="(state, i) in arrayStates"
            :key="i"
          >
            <v-radio
              :label = state.name
              :value = state.id
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray darken-1"
            text
            @click="close(false)"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="pink darken-1"
            text
            @click="$emit('setStatus', { order: item, dialog: 'state' })"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { getAllStatus } from '@/services/status.js'
export default {
  name: 'StateDialog',
  created () {
    this.getStatus()
  },
  props: {
    dialog: Boolean,
    item: Object
  },
  data: () => ({
    arrayStates: Array
  }),
  methods: {
    ...mapActions(['signout', 'handleAlert']),

    close (val) {
      this.$emit('closeDialog', 'state')
    },

    async getStatus () {
      try {
        const res = await getAllStatus(this.userToken)
        this.arrayStates = res.data
      } catch (error) {
        this.handleMessageErrorApi(error)
      }
    },

    handleMessageErrorApi (error) {
      this.alertColor = 'error'
      if (error.status === 401) {
        this.signout()
        this.alertMessage = 'Sesión caducada'
      } else {
        if (error.status === 422) {
          const firstError = Object.keys(error.data.errors)[0]
          this.alertMessage = error.data.errors[firstError][0]
        } else {
          this.alertMessage = error.status
        }
      }
      this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
    }
  },

  computed: {
    ...mapState(['userToken']),
    controlOpenDialog: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('handleDialog', value)
      }
    }
  }
}
</script>
