<template>
  <div>
    <v-row class="mt-3">
      <v-col sm="12" md="6">
        <ViewCardLeft></ViewCardLeft>
      </v-col>
      <v-col sm="12" md="6">
        <ViewCardRight :item="item"></ViewCardRight>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ViewCardRight from './ViewCardTotal.vue'
import ViewCardLeft from './ViewCardLogo.vue'
export default {
  name: 'CardPreview',
  components: { ViewCardRight, ViewCardLeft },
  props: {
    item: Object
  }
}
</script>
