<template>
  <v-dialog
      v-model="controlOpenDialog"
      persistent
      max-width="915px"
    >
    <v-row class="ma-0">
      <v-col cols="4" class="pa-0">
        <PopupLeft :index="index" :color="color" :item="item" :edit="edit" @changeIndex="changeIndex" @changeColor="changeColor" @iconSelected="iconSelected"/>
      </v-col>
      <v-col cols="8" class="pa-0">
        <PopupRight :index="index" :color="color" :item="item" @closeDialog="$emit('closeDialog')" :edit="edit" />
      </v-col>
    </v-row>
  </v-dialog>
</template>
<script>
import PopupLeft from './PopupLeft.vue'
import PopupRight from './PopupRight.vue'
export default {
  components: { PopupLeft, PopupRight },
  props: { dialog: Boolean, item: Object, edit: Boolean },
  data: () => ({
    index: 0,
    color: 'white'
  }),
  created () {
    this.item.products_order.forEach(e => { e.orderCustomizable = this.productCustomizable(e) })
  },
  methods: {
    changeIndex (e) {
      this.index = e
    },
    changeColor (e) {
      this.item.products_order[this.index].color = e
    },
    iconSelected (value) {
      this.item.products_order[this.index].icon = value
    },
    productCustomizable (e) {
      return !!(e.product.customizable &&
          (!e.name || e.name === '') &&
          (!e.last_name || e.last_name === '') &&
          (!e.color || e.color === '') &&
          (!e.icon || e.icon === ''))
    }
  },
  computed: {
    controlOpenDialog: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('handleDialog', value)
      }
    }
  }
}
</script>
