<template>
  <v-list flat class="px-3" nav>
    <v-list-item-group color="primary">
      <v-list-item v-for="(item, i) in items" :key="i" :to="item.pathName">
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.text" class="text-left font-weight-medium"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mdiAccount, mdiStorefrontOutline } from '@mdi/js'

export default {
  name: 'MenuNavModules',

  data: () => ({
    items: [
      {
        text: 'Usuarios',
        icon: mdiAccount,
        pathName: '/users'
      },
      {
        text: 'Pedidos',
        icon: mdiStorefrontOutline,
        pathName: '/sales'
      }
    ]
  })
}
</script>

<style lang="scss" scoped>
</style>
