<template>
  <div :class="$vuetify.breakpoint.xs ? 'mx-4 mt-4' : 'mx-8 mt-8'">
    <SalesTable />
  </div>
</template>

<script>
import SalesTable from '@/components/sales/SalesTable'

export default {
  name: 'Sales',

  components: {
    SalesTable
  }
}
</script>

<style scoped>
</style>
