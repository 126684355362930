<template>
  <div class="mb-3" style="background-color:white;">
    <v-alert
      outlined
      :color="color"
      type="info"
    >
      <v-row align="center">
        <v-col class="grow">
          <span v-if="!enablePersonalize && state !== 'Despachado'" style="font-size:13px; color:black">¡Tu pedido está <strong :style="style">{{state}}</strong>!  Pronto estaremos enviando tu pedido. Podrás ver acá mismo cuando tu pedido este listo ;)</span>
          <span v-if="!enablePersonalize && state === 'Despachado'" style="font-size:13px; color:black">¡Tu pedido está <strong :style="style">{{state}}</strong>!  Pronto estarás recibiendo tu pedido. Podrás hacer seguimiento de tu pedido por acá ;)</span>
          <span v-if="enablePersonalize" style="font-size:13px; color:black">¡Solo falta <strong :style="style">personalizar tu producto</strong>! Termina de personalizar tu producto para que pongamos manos a la obra ;)</span>
        </v-col>
        <v-col class="shrink">
          <v-btn
            v-show="!enablePersonalize && item.products_order.length > 0"
            :color="color"
            plain
            class="personalize-btn pa-5"
            @click.stop="$emit('showPersonalization')"
            :style="'height:24px !important; backgroundColor: '+ backgroundColorTransparency"
          >
            <span style="font-family:Roboto; letter-spacing:0px">VER PERSONALIZACION</span>
          </v-btn>
          <v-btn
              v-show="enablePersonalize && item.products_order.length > 0"
              :color="color"
              plain
              class="personalize-btn"
              @click.stop="$emit('showPersonalization')"
              :style="'height:24px; backgroundColor:' + backgroundColorTransparency"
            >
              <span style="font-family:Roboto; letter-spacing:2px">PERSONALIZAR</span>
            </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>
<script>
export default {
  name: 'SaleAlert',
  props: {
    item: Object,
    state: String,
    color: String,
    enablePersonalize: Boolean
  },
  computed: {
    style () {
      return 'color: ' + this.color
    },
    backgroundColorTransparency () {
      return this.color.replace('1)', '0.2)')
    }
  }
}
</script>
<style scoped lang="scss">
  ::v-deep {
    .v-alert {
      padding: 16px !important;
      .v-alert__wrapper {
        .v-icon {
          align-self: center;
        }
      }
    }
    .personalize-btn {
      span {
        span {
          font-size: 0.8rem !important;
          font-weight: 500;
        }
      }
    }
  }
</style>
