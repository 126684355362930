import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userToken: '',
    userDb: {},
    showMobileMenu: null,
    showLoading: false,
    alertOptions: { value: false, text: '', color: 'green darken-1' },
    textSearch: ''
  },
  mutations: {
    setMobileMenu (state, val) {
      state.showMobileMenu = val
    },

    setUserStatus (state, payload) {
      if (payload === '') {
        state.userToken = ''
        state.userDb = {}
      } else {
        state.userToken = payload.token
        state.userDb = payload.user
      }
    },

    setLoading (state, payload) {
      state.showLoading = payload
    },

    setAlert (state, val) {
      state.alertOptions.value = val.value ? val.value : false
      state.alertOptions.text = val.text ? val.text : ''

      const colors = { success: 'green darken-1', error: 'red darken-1' }
      if (val.color) {
        state.alertOptions.color = colors[val.color] || colors.success
      }
    },

    setTextSearch (state, val) {
      state.textSearch = val
    }
  },
  actions: {
    handleMobileMenu ({ commit }, payload) {
      commit('setMobileMenu', payload)
    },

    saveUser ({ commit }, payload) {
      localStorage.setItem('userInfoToken', JSON.stringify(payload))
      commit('setUserStatus', payload)
    },

    signout ({ commit }) {
      commit('setUserStatus', '')
      localStorage.removeItem('userInfoToken')
      localStorage.removeItem('currentPageSales')
    },

    checkUserLogged ({ commit }) {
      const payload = localStorage.getItem('userInfoToken')

      if (payload) {
        commit('setUserStatus', JSON.parse(payload))
      } else {
        commit('setUserStatus', '')
      }
    },

    handleLoading ({ commit }, payload) {
      commit('setLoading', payload)
    },

    handleAlert ({ commit }, payload) {
      commit('setAlert', payload)
    }
  },
  modules: {},
  getters: {
    mobileOpen: state => state.showMobileMenu,

    userLogged: state => !!state.userToken,

    alertOpen: state => state.alertOptions.value
  }
})
