<template>
  <div class="d-flex flex-row justify-between align-center" style="width: 100%">
    <v-text-field
      label="Buscar..."
      single-line
      solo
      flat
      hide-details
      :prepend-inner-icon="search"
      class="text-subtitle-1 font-weight-medium"
      @input="inputSearch"
    ></v-text-field>

    <v-btn
      @click="userDisconnect"
      color="transparent"
      elevation="0"
      small
      class="text-capitalize text-subtitle-2 text--secondary"
    >
      Cerrar Sesión
      <v-icon class="ml-2" size="26">{{ exit }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { userLogout } from '@/services/users'
import { mdiMagnify, mdiExitToApp } from '@mdi/js'

export default {
  name: 'HeaderContent',

  data: () => ({
    search: mdiMagnify,
    exit: mdiExitToApp,
    alertMessage: '',
    alertColor: ''
  }),

  computed: {
    ...mapState(['userToken', 'textSearch'])
  },

  methods: {
    ...mapActions(['signout', 'handleLoading', 'handleAlert']),

    inputSearch (val) {
      this.$store.commit('setTextSearch', val)
    },

    async userDisconnect () {
      try {
        this.handleLoading(true)
        const res = await userLogout(this.userToken)
        this.signout()
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        this.alertColor = 'error'
        if (error.status === 401) {
          this.signout()
          this.alertMessage = 'Sesión caducada'
        } else {
          this.alertMessage = error.data.message
        }
      } finally {
        this.handleLoading(false)
        this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
