import axios from './instance'

export const updateProduct = async (order, obj, token) => {
  try {
    const res = await axios.put(`/customize_product/${order}`, obj)
    return res
  } catch (err) {
    throw err.response
  }
}
