<template>
  <div>
    <v-card
        elevation="0"
        height="115"
        class="d-flex justify-center align-center"
    >
      <v-container>
        <v-row>
          <v-col cols="7" class="pl-0">
            <v-row style="align-items:flex-end">
              <v-col cols="6" class="">
                <v-card-subtitle class="text-left pb-0" style="white-space:nowrap;font-size:12px">Total Productos</v-card-subtitle>
              </v-col>
              <v-col cols="6" class="">
                <v-card-text class="text-right pb-0" style="font-size:13px">{{total_products}}</v-card-text>
              </v-col>
            </v-row>
            <div class="border-dashed mt-2"></div>
            <v-row>
                <v-col cols="6" class="">
                <v-card-subtitle class="text-left pb-0" style="white-space:nowrap;font-size:12px">{{item.shipping_line}}</v-card-subtitle>
              </v-col>
              <v-col cols="6" class="">
                <v-card-text class="text-right pb-0" style="font-size:13px">{{item.shipping_cost}}</v-card-text>
              </v-col>
            </v-row>
            <div class="border-dashed mt-2"></div>
          </v-col>
          <v-col
            cols="5"
            class="text-center"
            align-self="center"
          >
            <v-card-text class="pb-0" style="font-size:16px">Total Compra</v-card-text>
            <v-card-text class="pt-0" style="font-size:16px">{{item.total_amount}}</v-card-text>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'ViewCardTotal',
  props: {
    item: Object
  },
  computed: {
    total_products: function () {
      let sum = 0
      this.item.products_order.forEach((item) => { sum += parseFloat(item.product_total) })
      return sum
    }
  }
}
</script>
<style lang="scss" scoped>
.border-dashed {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E0E0E0FF' stroke-width='4' stroke-dasharray='1%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  width: 100%;
  height: 2px;
}
</style>
