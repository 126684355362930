<template>
  <div>
    <v-app-bar app color="white" flat height="90">
      <v-app-bar-nav-icon @click="btnMobile()" class="hidden-lg-and-up mx-1"></v-app-bar-nav-icon>

      <HeaderContent />
    </v-app-bar>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import HeaderContent from './header/HeaderContent'

export default {
  name: 'Header',

  components: {
    HeaderContent
  },

  data: () => ({}),

  computed: {
    ...mapState(['showMobileMenu', 'textSearch'])
  },

  methods: {
    ...mapActions(['handleMobileMenu']),

    btnMobile () {
      this.handleMobileMenu(!this.showMobileMenu)
    }
  }
}
</script>

<style lang="scss" scoped></style>
