var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{class:_vm.elevation,attrs:{"headers":_vm.headers,"items":_vm.dataTable,"single-expand":false,"expanded":_vm.controlExpanded,"items-per-page":30,"page":_vm.currentPageComputed,"sort-by":'created_at',"sort-desc":true,"hide-default-footer":"","item-key":"id","show-expand":""},on:{"update:expanded":function($event){_vm.controlExpanded=$event},"update:page":function($event){_vm.currentPageComputed=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_vm._t("paginationTable",null,{"pagination":pagination,"options":options,"updateOptions":updateOptions})]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-left"},[_vm._t("iconInsideColumn",null,{"item":item}),_c('a',{attrs:{"href":("https://etiquecosas.com.ar/wp-admin/post.php?post=" + (item.id_external) + "&action=edit"),"target":"_blanck"}},[_vm._v(" "+_vm._s(item.id_external)+" ")])],2)]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [(item.order_status.id === 5 && _vm.stringCompare(item.shipping_line))?_c('v-chip',{staticClass:"px-3 caption",attrs:{"color":_vm.getColor({ state: item.order_status, shipping: item.shipping_line }),"dark":""},on:{"click":function($event){return _vm.handleState(item)}}},[_vm._v(" "+_vm._s(item.order_status.name)+" para retirar 😍 ")]):(item.order_status.id === 5 && !_vm.stringCompare(item.shipping_line))?_c('v-chip',{staticClass:"px-3 caption",attrs:{"color":_vm.getColor({ state: item.order_status, shipping: item.shipping_line }),"dark":""},on:{"click":function($event){return _vm.handleState(item)}}},[_vm._v(" "+_vm._s(item.order_status.name)+" para despachar 😍 ")]):_c('v-chip',{staticClass:"px-3 caption",attrs:{"color":_vm.getColor({ state: item.order_status, shipping: item.shipping_line }),"dark":""},on:{"click":function($event){return _vm.handleState(item)}}},[_vm._v(" "+_vm._s(item.order_status.name)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.returnDate(item.created_at))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_vm._t("expandedItemTable",null,{"item":item,"headers":headers})]}},{key:"item.data-table-preview",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.preview(item)}}},[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_vm._t("columnExpandTable",null,{"item":item,"isExpanded":isExpanded,"expand":expand})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }