import axios from './instance'

const configToken = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export const getAllOrders = async (token) => {
  try {
    const res = await axios.get('/orders', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getOrder = async (order) => {
  try {
    const res = await axios.get(`/orders/${order}`)
    return res
  } catch (err) {
    throw err.response
  }
}

export const updateCommentary = async (order, token) => {
  try {
    const res = await axios.put(`/order_commentary/${order.id}`, order, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const updateStatus = async (order, token) => {
  try {
    const res = await axios.put(`/order_status/${order.id}`, order, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}
