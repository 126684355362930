<template>
  <div>
    <slot name="outsideTable"></slot>
    <v-scale-transition hide-on-leave leave-absolute>
      <v-data-table
        v-if="!(hideTableOnNewItem && openNewItem)"
        :headers="headers"
        :items="dataTable"
        :search="textSearch"
        sort-by="calories"
        class="elevation-1"
      >
        <template v-slot:top>
          <slot name="insideTable"></slot>

          <slot name="componentDelete"></slot>
        </template>

        <template v-slot:item.actions="{ item }">
          <slot name="actionsColumnTable" :item="item"></slot>
        </template>

        <template v-slot:no-data>
          <slot name="noDataTable"></slot>
        </template>
      </v-data-table>
    </v-scale-transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  created () {
    this.initialize()
  },
  props: {
    dataTable: Array,
    headers: Array,
    hideTableOnNewItem: { type: Boolean, default: false },
    openNewItem: { type: Boolean, default: false }
  },
  data: () => ({}),
  methods: {
    initialize () {
      this.$store.commit('setTextSearch', '')
    }
  },
  computed: {
    ...mapState(['textSearch'])
  }
}
</script>

<style lang="scss" scoped>
::v-deep .text-center {
  span {
    margin-left: 18px !important;
  }
}
</style>
