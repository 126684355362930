<template>
  <div class="mb-4">
    <v-col cols="12" sm="5">
      <v-row>
        <v-btn icon color="rgba(157, 157, 157, 1)" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span style="font-size:14px; align-self:center; color:rgba(157, 157, 157, 1)">Volver atrás</span>
        <v-col cols="6" sm="8" class="pt-0 pb-0" style="align-self:center; text-align:left">
          <strong style="font-size:16px">Detalle Pedido {{item.id}}</strong>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>
<script>
export default {
  props: { item: Object }
}
</script>
