<template>
    <v-card class="mb-3" elevation="0">
        <v-row align="center" style="height:100%">
            <v-col class="" align-self="center">
                <v-row class="pl-4" justify="start">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateRangeText"
                          label="Rango desde - hasta"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        no-title
                        range
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="menu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                    <v-select
                    class="pl-3 col-5"
                    label="Seleccionar estado"
                    v-model="state"
                    :items="items"
                    item-text="name"
                    item-value="id"
                    hide-details>
                    </v-select>
                </v-row>
            </v-col>
            <v-col align-self="center" style="text-align: left;">
                <v-btn
                text
                :color="'rgba(255, 0, 153, 1)'"
                plain
                style="background-color:rgba(255, 0, 153, 0.2)"
                @click="filterArray">
                    Aplicar filtro
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { getAllStatus } from '@/services/status.js'
import moment from 'moment'
export default {
  data: () => ({
    state: 0,
    date: [moment().subtract(20, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    items: [],
    menu: false
  }),
  created () {
    this.getStatus()
    this.filterArray()
  },
  methods: {
    ...mapActions(['signout', 'handleAlert']),

    async getStatus () {
      try {
        const res = await getAllStatus(this.userToken)
        this.items = res.data
      } catch (error) {
        this.handleMessageErrorApi(error)
      }
    },

    handleMessageErrorApi (error) {
      this.alertColor = 'error'
      if (error.status === 401) {
        this.signout()
        this.alertMessage = 'Sesión caducada'
      } else {
        if (error.status === 422) {
          const firstError = Object.keys(error.data.errors)[0]
          this.alertMessage = error.data.errors[firstError][0]
        } else {
          this.alertMessage = error.status
        }
      }
      this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
    },

    filterArray () {
      this.$emit('filter', { state: this.state, date: this.date })
    }
  },
  computed: {
    ...mapState(['userToken']),

    dateRangeText () {
      return moment(this.date[0]).format('DD/MM/YYYY') + ' - ' + moment(this.date[1]).format('DD/MM/YYYY')
    }
  }
}
</script>

<style scoped lang="scss">
.v-card {
  background-color: transparent !important;
}
</style>
