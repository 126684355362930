<template>
  <v-card flat :class="$vuetify.breakpoint.xs ? 'mr-0' : 'mr-6'">
    <v-card-title class="pt-6">
      <span class="subtitle-1 font-weight-medium text--secondary">{{
        NewItemTitle()
      }}</span>
    </v-card-title>

    <form @submit.prevent="submit()" novalidate autocomplete="off">
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              :md="NewItemInputValues.length > 4 ? '4' : null"
              v-for="(item, i) in NewItemInputValues"
              :key="i"
            >
              <v-select
                v-if="item.type === 'select'"
                v-model="editedItem[item.value]"
                :items="NewItemInputSelect[item.value]"
                :label="item.text"
                :error="errorsProps(item.value)"
                required
                dense
              ></v-select>

              <v-text-field
                v-else
                v-model="editedItem[item.value]"
                :label="item.text"
                :error="errorsProps(item.value)"
                required
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-for="(item, i) in btnNewItemProps"
          :key="i"
          :color="item.style"
          :text="item.bgOff"
          :loading="item.event === 'save' ? loadingSave : false"
          @click="item.type === 'submit' ? submit() : $emit(item.event)"
          >{{ item.text }}</v-btn
        >
      </v-card-actions>
    </form>
  </v-card>
</template>

<script>
export default {
  name: 'NewItem',
  props: {
    openNewItem: Boolean,
    editedItem: Object,
    NewItemTitle: Function,
    NewItemInputValues: Array,
    NewItemInputSelect: Object,
    loadingSave: { type: Boolean, default: false },
    btnNewItemProps: {
      type: Array,
      default () {
        return [
          { text: 'Cancelar', event: 'close', style: 'grey lighten-1', bgOff: true, type: 'button' },
          { text: 'Aceptar', event: 'save', style: 'primary', bgOff: false, type: 'submit' }
        ]
      }
    },
    errorsValidationProps: {
      type: Function
    },
    validationsProps: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    isError: false
  }),
  validations () {
    return this.validationsProps
  },
  watch: {
    openNewItem () {
      if (!this.openNewItem) {
        this.$v.$reset()
      }
    }
  },
  methods: {
    submit () {
      if (this.validationsProps) {
        this.$v.editedItem.$touch()
        if (this.$v.editedItem.$error) return
        this.$emit('save')
      } else {
        this.$emit('save')
      }
    },

    errorsProps (value) {
      if (this.errorsValidationProps) {
        if (!this.$v.editedItem[value].$dirty) return false
        return this.errorsValidationProps(value, this.$v.editedItem[value])
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
