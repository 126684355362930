<template>
  <v-col class="pa-0">
    <div style="width:100%; height: 542px; max-height:542px; background-color:rgba(247, 247, 247, 1)">
      <v-col style="height:100%">
        <v-row justify="center" style="height:45%">
          <v-col>
            <v-row justify="center" style="height:35%">
              <div style="align-self: flex-end;">
                <label style="font-family:Roboto; font-size:13px">Vista Previa Etiqueta MAXI</label>
              </div>
            </v-row>
            <v-row style="height:65%" class="d-flex align-center justify-center">
              <div style="width:225px; height:75px; display: inline-flex; background-color:white; border: solid 1px #CCCCCC">
                <div style="width:75px; height:73px">
                  <div style="">
                    <v-img :src="getImgUrl(item.products_order[index].icon)"></v-img>
                  </div>
                </div>
                <div :style='`width:150px; height:73px; background-color:${item.products_order[index].product.customizable ? item.products_order[index].color : "white"}; display: grid`'>
                    <strong v-show="item.products_order[index].product.customizable" class="label" style="color:white">{{upperDetail(item.products_order[index].name)}}</strong>
                    <strong v-show="item.products_order[index].product.customizable" class="label" style="color:white">{{upperDetail(item.products_order[index].last_name)}}</strong>
                </div>
              </div>
            </v-row>
          </v-col>
          <v-col>
            <v-row justify="center" style="height:35%">
              <div style="align-self: flex-end;">
                <label style="font-family:Roboto; font-size:13px">Vista Previa Etiqueta SPOT</label>
              </div>
            </v-row>
            <v-row style="height:65%" class="">
              <div style="width:133px; height:133px; margin:auto">
                <div style='border-radius: 50%; background: url("/Vector.png"); width: 100%;height: 100%;'>
                    <div>
                      <!-- <VueArcText :text="item.products_order[index].name.toUpperCase()" :arc="70" :direction="1" style="max-height:36px; min-height:36px"></VueArcText> -->
                      <svg data-v-07cc575b="" viewBox="0 0 133 35" width="133" height="35">
                        <g>
                          <path stroke="none" d="M 0,70 Q 66,-40 133,70" id="textnombre" fill="none"></path>
                          <text class="label">
                            <textPath v-show="item.products_order[index].product.customizable" xlink:href="#textnombre" :fill='`${item.products_order[index].color}`' startOffset="50%" text-anchor="middle">
                              {{upperDetail(item.products_order[index].name)}}
                            </textPath>
                          </text>
                        </g>
                      </svg>
                    </div>
                    <div style="width:58px; height:58px; margin:auto">
                      <div class="d-flex align-center">
                        <v-img :src="getImgUrl(item.products_order[index].icon)"></v-img>
                      </div>
                    </div>
                    <div>
                      <!-- <VueArcText :text="item.products_order[index].last_name.toUpperCase()" :arc="70" :direction="-1" style="max-height:36px; min-height:36px; bottom:0px !important"></VueArcText> -->
                      <div data-v-07cc575b="">
                        <svg data-v-07cc575b="" viewBox="0 0 133 40" width="133" height="40">
                          <g>
                            <path stroke="none" d="M 0,-15 Q 66.5,75 133,-15" id="textapellido" fill="none"></path>
                            <text class="label" style="letter-spacing:2px">
                              <textPath v-show="item.products_order[index].product.customizable" xlink:href="#textapellido" :fill='`${item.products_order[index].color}`' startOffset="50%" text-anchor="middle">
                                {{upperDetail(item.products_order[index].last_name)}}
                              </textPath>
                            </text>
                          </g>
                        </svg>
                      </div>
                    </div>
                </div>
              </div>
            </v-row>
          </v-col>
        </v-row>
        <v-row style="height:45%">
          <v-col>
            <v-row justify="center" style="height:45%">
              <div style="align-self: flex-end;">
                <label style="font-family:Roboto; font-size:13px">Vista Previa Etiqueta PLANCHABLE</label>
              </div>
            </v-row>
            <v-row style="height:55%" class="d-flex align-center justify-center">
              <div style="width:225px; height:55px; background-color:white; display: inline-flex; border: solid 1px #CCCCCC">
                <div style="width:55px; height:55px" class="d-flex">
                  <div style="width: 45px;height: 45px;position: relative;align-self: center;justify-content: center;" class="ml-2">
                    <v-img :src="getImgUrl(item.products_order[index].icon)"></v-img>
                  </div>
                </div>
                <div style="background-color:white; width:170px" class="d-flex align-center justify-center">
                  <strong v-show="item.products_order[index].product.customizable" class="label" :style='`color:${item.products_order[index].color.toUpperCase()}`'>{{upperDetail(item.products_order[index].name)}} {{upperDetail(item.products_order[index].last_name)}}</strong>
                </div>
              </div>
            </v-row>
          </v-col>
          <v-col align-self="center">

          </v-col>
        </v-row>
        <v-row style="height:10%" class="float-right">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="gray darken-1"
              text
              @click="close()"
            >
              Cancelar
            </v-btn>
            <v-btn
              v-show="edit && item.products_order[index].orderCustomizable"
              color="pink darken-1"
              text
              @click="setProduct()"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-col>
    </div>
  </v-col>
</template>
<script>
import { mapActions } from 'vuex'
import { updateProduct } from '@/services/product_order.js'
// import VueArcText from 'vue-arc-text'
// import CurveText from '@inotom/vue-curve-text'
export default {
  props: {
    item: Object,
    index: Number,
    edit: Boolean
  },
  methods: {
    ...mapActions(['signout', 'handleLoading', 'handleAlert']),
    close () {
      this.$emit('closeDialog')
    },
    async setProduct () {
      try {
        this.handleLoading(true)
        const res = await updateProduct(this.item.products_order[this.index].id, this.item.products_order[this.index])
        this.handleMessageSuccessApi(res.data.message)
        this.close()
      } catch (error) {
        this.handleMessageErrorApi(error)
      } finally {
        this.handleLoading(false)
      }
    },
    handleMessageErrorApi (error) {
      this.alertColor = 'error'
      if (error.status === 401) {
        this.signout()
        this.alertMessage = 'Sesión caducada'
      } else {
        if (error.status === 422) {
          const firstError = Object.keys(error.data.errors)[0]
          this.alertMessage = error.data.errors[firstError][0]
        } else {
          this.alertMessage = error.status
        }
      }
      this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
    },
    handleMessageSuccessApi (message) {
      this.alertColor = 'success'
      this.alertMessage = message
      this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
    },
    getImgUrl (name) {
      try {
        var images = require.context('@/assets/icons/etiquetas/', false, /\.png$/)
        return images('./' + name + '.png')
      } catch (error) {
        return ''
      }
    },
    upperDetail (val) {
      if (val) {
        return val.toUpperCase()
      }
      return ''
    }
  }
}
</script>
<style scoped>
  .bg-lineas-diagonales{
      background: repeating-linear-gradient(
        126deg,
        rgba(32, 92, 159, 1) 38px,
        rgba(0, 0, 0, 0) 41px,
        white 40px,
        white 43px
    )
  }
  .label{
    align-self: center;
    font-family: Oswald Regular
  }
  @font-face {
    font-family: 'Oswald Regular';
    font-style: normal;
    font-weight: 400;
    src:
      local('Oswald Regular'),
      url(/fonts/Oswald-Regular.ttf) format('truetype')
  }
</style>
