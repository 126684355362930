<template>
  <v-card
        elevation="0"
        height="115"
        class="d-flex justify-center align-center"
    >
      <v-container>
        <v-row>
          <v-col cols="3" class="d-flex justify-center">
            <v-img
              max-height="79px"
              max-width="80px"
              src="@/assets/img/logo.png"
            ></v-img>
          </v-col>

          <v-col
            cols="9"
            class="text-left pl-0"
            align-self="center"
            style=""
          >
            <v-card-text class="pb-0 pl-0" style="font-size:15px">Ponele nombre a todo, y ¡qué no se pierda nada!</v-card-text>
            <v-btn class="pa-0" text style="color:#FF0099" href="https://etiquecosas.com.ar/" target="_blank">Seguir comprando en la web --></v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
</template>
<script>
export default {
  name: 'ViewCardLogo'
}
</script>
