<template>
  <v-data-table
    :headers="headers"
    :items="dataTable"
    :single-expand="false"
    :expanded.sync="controlExpanded"
    :items-per-page="30"
    :page.sync="currentPageComputed"
    :sort-by="'created_at'"
    :sort-desc="true"
    hide-default-footer
    item-key="id"
    show-expand
    :class="elevation"
  >
    <template v-slot:top="{ pagination, options, updateOptions }">
      <slot
        name="paginationTable"
        :pagination="pagination"
        :options="options"
        :updateOptions="updateOptions"
      ></slot>
    </template>

    <template v-slot:item.number="{ item }">
      <div class="d-flex justify-left">
        <slot name="iconInsideColumn" :item="item"></slot>

        <a :href='`https://etiquecosas.com.ar/wp-admin/post.php?post=${item.id_external}&action=edit`' target="_blanck">
          {{item.id_external}}
        </a>
      </div>
    </template>

    <template v-slot:item.state="{ item }">
      <v-chip v-if="item.order_status.id === 5 && stringCompare(item.shipping_line)" :color="getColor({ state: item.order_status, shipping: item.shipping_line })" dark class="px-3 caption" @click="handleState(item)">
        {{item.order_status.name}} para retirar 😍
      </v-chip>
      <v-chip v-else-if="item.order_status.id === 5 && !stringCompare(item.shipping_line)" :color="getColor({ state: item.order_status, shipping: item.shipping_line })" dark class="px-3 caption" @click="handleState(item)">
        {{item.order_status.name}} para despachar 😍
      </v-chip>
      <v-chip v-else :color="getColor({ state: item.order_status, shipping: item.shipping_line })" dark class="px-3 caption" @click="handleState(item)">
        {{item.order_status.name}}
      </v-chip>
    </template>

    <template v-slot:item.created_at="{ item }">
        {{ returnDate(item.created_at) }}
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <slot name="expandedItemTable" :item="item" :headers="headers"></slot>
    </template>

    <template v-slot:item.data-table-preview="{ item }">
      <v-btn @click="preview(item)" icon>
        <v-icon>mdi-eye-outline</v-icon>
      </v-btn>
    </template>

    <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
      <slot
        name="columnExpandTable"
        :item="item"
        :isExpanded="isExpanded"
        :expand="expand"
      ></slot>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from 'vuex'
import { mdiEyeOutline } from '@mdi/js'
import moment from 'moment'
export default {
  name: 'AppTable',
  created () {
    this.initialize()
  },
  props: {
    dataTable: Array,
    headers: Array,
    itemKey: { type: String, default: 'number' },
    getColor: Function,
    expanded: Array,
    elevation: String,
    currentPage: Number
  },
  data: () => ({ eyeicon: mdiEyeOutline }),
  methods: {
    initialize () {
      this.$store.commit('setTextSearch', '')
    },

    handleState (val) {
      this.$emit('handleState', { option: 0, itemPreview: val })
    },

    preview (data) {
      this.$router.push({ path: `/preview/${data.id}` })
    },

    returnDate (date) {
      return moment(date).format('DD/MM/YYYY')
    },
    stringCompare (sentence) {
      return sentence.includes('Retiro oficina')
    }
  },
  computed: {
    ...mapState(['textSearch']),

    controlExpanded: {
      get () { return this.expanded },
      set () { this.$emit('handleExpandedItem') }
    },

    currentPageComputed: {
      get () { return this.currentPage },
      set (val) { this.$emit('updateOptions', val) }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-table
  > ::v-deep.v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none !important;
  background-color: #f3f3f3;
}
</style>
