<template>
  <div>
    <v-icon
      v-if="circleReference"
      class="mr-2 circle"
      :color="item[circleReference] !== '0' ? 'success' : 'error'"
    >
      {{ circleIcon }}
    </v-icon>
    <v-icon small class="mr-2" @click="$emit('editItem', item)">
      mdi-pencil
    </v-icon>
    <v-icon small @click="$emit('deleteItem', item)"> mdi-delete </v-icon>
  </div>
</template>

<script>
import { mdiCircleMedium } from '@mdi/js'

export default {
  name: 'ActionColumnTable',
  props: {
    item: Object,
    circleReference: String
  },
  data: () => ({
    circleIcon: mdiCircleMedium
  })

}
</script>

<style lang="scss" scoped>
.circle {
  margin-top: 0.12em;
}
</style>
