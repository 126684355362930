<template>
  <v-data-footer
    :pagination="pagination"
    :options="options"
    :items-per-page-options="[30, 40, 50, -1]"
    @update:options="updateOptions"
    items-per-page-text="Mostrar:"
  />
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    options: Object,
    pagination: Object,
    updateOptions: Function
  }
}
</script>

<style lang="scss" scoped>
</style>
