<template>
  <v-col class="pa-0">
    <div style="width:100%; height: 542px; max-height:542px; background-color:white">
            <v-card-title style="font-size:16px">Productos a personalizar</v-card-title>
            <v-divider></v-divider>
            <v-list :height='`${edit ? "104px" : "475px"}`' style="overflow-y:auto">
              <v-list-item-group v-model="model" mandatory color="pink" class="pl-2 pr-2">
                <v-list-item
                  style="background-color:rgba(157, 157, 157, 0.1); min-height:32px; margin-bottom: 5px"
                  @click="setItem(i)"
                  v-for="(row, i) in item.products_order"
                  v-show="item.products_order[i].product.customizable"
                  :key="i"
                >
                  <v-list-item-content
                    class="pb-0 pt-0">
                    <v-list-item-title v-text="row.product.name" class="text-left"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon v-show="model === i" style="margin-top: 5px; margin-left: 0px; margin-bottom: 0px;">
                    <v-icon> mdi-arrow-right </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-divider></v-divider>
            <v-col v-if="edit && item.products_order[index].orderCustomizable" cols="12" class="pa-0">
              <v-btn-toggle
                style="width:100%"
                v-model="optionSelected"
                tile
                color="pink"
                group
              >
                <v-btn
                  class="ma-0"
                  style="width:50%;"
                  dense
                  :elevation="0"
                  @click="optionSelected = 0"
                >
                  <span style="font-size:10px">Editar Contenido</span>
                </v-btn>
                <v-btn
                  class="ma-0"
                  style="width:50%"
                  dense
                  :elevation="0"
                  @click="optionSelected = 1"
                >
                  <span style="font-size:10px">Elegir Personaje</span>
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <TabEdit v-show="optionSelected === 0 && edit && item.products_order[index].orderCustomizable" :item="item" :index="index" @changeColor="changeColor" />
            <TabIcons v-show="optionSelected === 1 && edit && item.products_order[index].orderCustomizable" @iconSelected="iconSelected" />
        </div>
  </v-col>
</template>
<script>
import TabEdit from './TabEdit.vue'
import TabIcons from './TabIcons.vue'
export default {
  components: { TabEdit, TabIcons },
  props: {
    dialog: Boolean,
    item: Object,
    index: Number,
    color: String,
    edit: Boolean
  },
  data: () => ({
    model: null,
    optionSelected: 0
  }),
  methods: {
    setItem (i) {
      this.$emit('changeIndex', i)
    },
    changeColor (val) {
      this.$emit('changeColor', val)
    },
    iconSelected (val) {
      this.$emit('iconSelected', val)
    }
  }
}
</script>
<style scoped>

</style>
