<template>
  <div>
    <FilterComponent v-if="allOrders.length > 0" @filter="filter"/>
    <AppTable
      :dataTable="dataTable"
      :headers="headers"
      :getColor="getColor"
      :elevation="'elevation-1'"
      :expanded="expanded"
      @handleExpandedItem="handleExpandedItem"
      :currentPage="currentPage"
      @updateOptions="updateOptions"
      @handleState="handleSelectedMenuItem"
    >
      <template v-slot:paginationTable="slotProps">
        <Pagination
          :pagination="slotProps.pagination"
          :options="slotProps.options"
          :updateOptions="slotProps.updateOptions"
        />
      </template>

      <template v-slot:iconInsideColumn="slotProps">
        <component :is="setIcon(slotProps.item.id_sales_channel)" class="mr-4"/>
      </template>

      <template v-slot:columnExpandTable="slotProps">
        <ColumnExpand
          :item="slotProps.item"
          :isExpanded="slotProps.isExpanded"
          :expand="slotProps.expand"
          :selectedMenuItem="selectedMenuItem"
          :itemsMenuList="itemsMenuList"
          @handleSelectedMenuItem="handleSelectedMenuItem"
        />
      </template>

      <template v-slot:expandedItemTable="slotProps">
        <ExpandedItem
          :item="slotProps.item"
          :headers="slotProps.headers"
          :expandHeaders="expandHeaders"
        />
      </template>
    </AppTable>
    <StateDialog :item="editedItem" :dialog="showDialog" @setStatus="updateOrder" @closeDialog="closeDialog"></StateDialog>
    <ComentaryDialog :item="editedItem" :dialog="showDialogComentary" @setCommentary="updateOrder" @closeDialog="closeDialog"></ComentaryDialog>
  </div>
</template>

<script>
import AppTable from '@/components/views/expandTable/AppTable.vue'
import Pagination from '@/components/views/expandTable/tableComponents/Pagination.vue'
import ColumnExpand from '@/components/views/expandTable/tableComponents/ColumnExpand.vue'
import ExpandedItem from '@/components/views/expandTable/tableComponents/ExpandedItem.vue'
import { MeliIcon, WooIcon } from '@/assets/icons'
import SalePreview from '@/components/preview/SalePreview.vue'
import StateDialog from '@/components/preview/StateDialog.vue'
import ComentaryDialog from '@/components/sales/ComentaryDialog.vue'
import { mapActions, mapState } from 'vuex'
import { getAllOrders, updateStatus, updateCommentary } from '@/services/orders.js'
import moment from 'moment'
import FilterComponent from './Filter.vue'
export default {
  name: 'SalesTable',
  components: { AppTable, Pagination, ColumnExpand, ExpandedItem, MeliIcon, WooIcon, SalePreview, StateDialog, ComentaryDialog, FilterComponent },
  data: () => ({
    expanded: [],
    singleExpand: false,
    selectedMenuItem: null,
    itemsMenuList: [
      { text: 'Cambiar Estado' },
      { text: 'Vista Previa' },
      { text: 'Copiar Enlace ' },
      { text: 'Comentario Interno' }
    ],
    expandHeaders: [
      { text: 'Producto', value: 'product_name', align: 'left' },
      { text: 'Nombre', value: 'name', align: 'left' },
      { text: 'Apellido', value: 'last_name', align: 'left' },
      { text: 'Personaje', value: 'icon', align: 'left' },
      { text: 'Color', value: 'color', align: 'left' },
      { text: 'Cantidad', value: 'quantity', align: 'center' },
      { text: 'Variacion', value: 'variants', align: 'left' },
      { text: 'comentario', value: 'commentary', align: 'left' },
      { text: 'Precio', value: 'unit_amount', align: 'center' },
      { text: 'Total', value: 'product_total', align: 'center' }
    ],
    headers: [
      { text: 'Numero', value: 'number', align: 'left' },
      { text: 'Correo', value: 'client.email', align: 'left' },
      { text: 'Fecha', value: 'created_at', align: 'left' },
      { text: 'Estado', value: 'state', align: 'left' },
      { text: 'Envío', value: 'shipping_line', align: 'left' },
      { text: 'Total', value: 'total_amount', align: 'center' },
      { text: '', value: 'data-table-preview', align: 'right' },
      { text: '', value: 'data-table-expand', align: 'right' }
    ],
    dataTable: [],
    allOrders: [],
    allFiltered: [],
    editedItem: null,
    editedIndex: -1,
    showPreview: null,
    showDialog: false,
    showDialogComentary: false,
    currentPage: 0
  }),

  computed: {
    ...mapState(['userToken', 'textSearch'])
  },

  watch: {
    textSearch (newValue) {
      const searchRegex = new RegExp(newValue, 'i')
      this.dataTable = this.allFiltered.filter((event) => {
        if (!newValue || searchRegex.test(event.id_external) || searchRegex.test(event.email)) return event

        if (event.products_order && event.products_order.length > 0) {
          for (let i = 0; i < event.products_order.length; i++) {
            if (searchRegex.test(event.products_order[i].name) || searchRegex.test(event.products_order[i].last_name)) return event
          }
        }
      })
    }
  },

  created () {
    this.initialize()
  },

  methods: {
    ...mapActions(['signout', 'handleLoading', 'handleAlert']),

    async initialize () {
      this.handleLoading(true)
      await this.getOrders()
      this.handleExpandedItem()
      this.setPage()
      this.handleLoading(false)
    },

    async getOrders () {
      try {
        const res = await getAllOrders(this.userToken)
        this.allOrders = this.formatDataTable(res.data)
        this.dataTable = this.allOrders
      } catch (error) {
        this.handleMessageErrorApi(error)
      }
    },

    formatDataTable (data) {
      if (Array.isArray(data)) {
        data.forEach(e => {
          this.assignData(e)
        })
      } else {
        this.assignData(data)
      }
      return data
    },

    assignData (e) {
      e.email = e.client.email
      e.products_order.forEach((v) => {
        v.name = v.product.customizable ? v.name : 'no personalizable'
        v.last_name = v.product.customizable ? v.last_name : 'no personalizable'
        v.icon = v.product.customizable ? v.icon.replace(' ', '-').toLowerCase().trim() : 'no personalizable'
        v.color = v.product.customizable ? v.color : 'no personalizable'
        v.variant = v.product.variant ? v.product.variant.replace(';', '\n') : ''
        v.product_name = v.product.name
        v.product_total = parseFloat(v.unit_amount) * parseFloat(v.quantity)
      })
    },

    handleSelectedMenuItem (val) {
      this.editedItem = val.itemPreview
      this.editedIndex = this.dataTable.indexOf(val.itemPreview)
      if (val.option === 1) {
        this.$router.push({ path: `/preview/${this.editedItem.id}` })
      } else if (val.option === 0) {
        this.showDialog = true
      } else if (val.option === 2) {
        let baseurl = null
        if (process.env.VUE_APP_API_URL_MODE === 'development') {
          baseurl = process.env.VUE_APP_ADMIN_URL_DEV
        } else {
          baseurl = process.env.VUE_APP_ADMIN_URL_PROD
        }
        var aux = document.createElement('input')
        aux.setAttribute('value', `${baseurl}/order/${this.editedItem.id}`)
        document.body.appendChild(aux)
        aux.select()
        document.execCommand('copy')
        document.body.removeChild(aux)
        this.handleMessageSuccessApi('Enlace copiado al portapapeles')
      } else {
        this.showDialogComentary = true
      }
    },

    setPage () {
      if (localStorage.getItem('currentPageSales')) {
        this.currentPage = Number(localStorage.getItem('currentPageSales'))
      } else {
        this.currentPage = 1
      }
    },

    updateOptions (val) {
      localStorage.setItem('currentPageSales', val)
    },

    handleExpandedItem (val) {
      this.expanded = this.allOrders
    },

    handleMessageSuccessApi (message) {
      this.alertColor = 'success'
      this.alertMessage = message
      this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
    },

    handleMessageErrorApi (error) {
      this.alertColor = 'error'
      if (error.status === 401) {
        this.signout()
        this.alertMessage = 'Sesión caducada'
      } else {
        if (error.status === 422) {
          const firstError = Object.keys(error.data.errors)[0]
          this.alertMessage = error.data.errors[firstError][0]
        } else {
          this.alertMessage = error.status
        }
      }
      this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
    },

    async updateOrder (params) {
      try {
        this.handleLoading(true)
        if (params.dialog === 'state') {
          const res = await updateStatus(params.order, this.userToken)
          Object.assign(this.dataTable[this.editedIndex], this.formatDataTable(res.data.order))
          this.handleMessageSuccessApi(res.data.message)
        } else {
          const res = await updateCommentary(params.order, this.userToken)
          Object.assign(this.dataTable[this.editedIndex], this.formatDataTable(res.data.order))
          this.handleMessageSuccessApi(res.data.message)
        }
        this.closeDialog(params.dialog)
      } catch (error) {
        this.handleMessageErrorApi(error)
      } finally {
        this.handleLoading(false)
      }
    },

    closeDialog (dialog) {
      dialog === 'state' ? this.showDialog = false : this.showDialogComentary = false
    },

    getColor (value) {
      let result = ''
      if (value.state.id === 5) {
        result = value.shipping.includes('Retiro oficina') ? 'Pedido listo para retirar' : 'Pedido listo para despachar'
      } else {
        result = value.state.name
      }
      const colors = {
        Aprobado: 'rgba(232, 0, 237, 1)',
        'En produccion': 'rgba(255, 114, 0, 0.35)',
        'Pedido listo para retirar': 'rgba(0, 237, 137, 1)',
        'Pedido listo para despachar': 'rgba(0, 150, 137, 1)',
        Cancelado: 'rgba(237, 71, 0, 1)'
      }
      return colors[result] || 'rgba(255, 114, 0, 0.35)'
    },

    setIcon (value) {
      const icon = {
        Meli: 'MeliIcon',
        Woo: 'WooIcon'
      }
      value = value === 2 ? 'Woo' : 'Meli'
      return icon[value] || 'div'
    },

    filter (params) {
      this.allFiltered = this.allOrders.filter(row => {
        if (params.date.length === 1) {
          if (moment(row.created_at) > moment(params.date[0])) {
            if (this.filterState(params, row)) return true
          } else {
            return false
          }
        } else {
          if (moment(row.created_at).isBetween(moment(params.date[0]), moment(params.date[1]).add(1, 'days'))) {
            if (this.filterState(params, row)) return true
          } else {
            return false
          }
        }
      })
      this.dataTable = this.allFiltered
    },
    filterState (params, row) {
      if (params.state) {
        if (row.order_status.id === params.state) return true
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
