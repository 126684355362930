<template>
  <AppTable :dataTable="dataTable" :headers="headers">
    <template v-slot:insideTable>
      <ToolbarInsideTable
        :openNewItem="openNewItem"
        :tableTitle="setTableTitle"
        :toolbarBtnText="setBtnNewItemText"
        @handleNewItem="handleNewItem"
      >
        <template v-slot:newItem>
          <NewItem
            :openNewItem="openNewItem"
            :editedItem="editedItem"
            :NewItemTitle="setNewItemTitle"
            :NewItemInputValues="NewItemInputValues"
            :NewItemInputSelect="NewItemInputSelect"
            :loadingSave="loadingSave"
            :validationsProps="validationsProps"
            :errorsValidationProps="errorsValidationProps"
            @close="close"
            @save="save"
          />
        </template>
      </ToolbarInsideTable>
    </template>

    <template v-slot:componentDelete>
      <DeleteItem
        :openItemDelete="openItemDelete"
        :editedItem="editedItem"
        :loading="loadingDelete"
        @handleItemDelete="handleItemDelete"
        @closeDelete="closeDelete"
        @deleteItemConfirm="deleteItemConfirm"
      />
    </template>

    <template v-slot:actionsColumnTable="slotProps">
      <ActionColumnTable
        :item="slotProps.item"
        @editItem="editItem"
        @deleteItem="deleteItem"
      />
    </template>

    <template v-slot:noDataTable
      ><v-btn color="primary" @click="initialize"> Reiniciar </v-btn>
    </template>
  </AppTable>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import AppTable from '@/components/views/dataTableOne/AppTable.vue'
import DeleteItem from '@/components/views/dataTableOne/layoutOne/deleteItem/DeleteItem.vue'
import ToolbarInsideTable from '@/components/views/dataTableOne/layoutTwo/toolbar/ToolbarInsideTable.vue'
import NewItem from '@/components/views/dataTableOne/layoutTwo/newItem/NewItem.vue'
import ActionColumnTable from '@/components/views/dataTableOne/layoutOne/columnActions/ActionColumnTable.vue'
import { getAllUsers, getAllRoles, createUser, deleteUser, updateUser } from '@/services/users'

export default {
  name: 'UsersTable',
  components: { AppTable, DeleteItem, ToolbarInsideTable, ActionColumnTable, NewItem },
  data: () => ({
    openNewItem: false,
    openItemDelete: false,
    dataTable: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    loadingSave: false,
    loadingDelete: false,
    validationsProps: {
      editedItem: {
        name: { required },
        last_name: { required },
        email: { required, email },
        role: { required },
        password: { }
      }
    },
    headers: [
      { text: 'Nombre', value: 'name', align: 'center' },
      { text: 'Apellido', value: 'last_name', align: 'center' },
      { text: 'Correo', value: 'email', align: 'center' },
      { text: 'Perfil', value: 'profile', align: 'center' },
      { text: '', value: 'actions', sortable: false, align: 'center' }
    ],
    NewItemInputValues: [
      { text: 'Nombre', value: 'name', type: 'text' },
      { text: 'Apellido', value: 'last_name', type: 'text' },
      { text: 'Correo', value: 'email', type: 'text' },
      { text: 'Perfil', value: 'role', type: 'select' },
      { text: 'Contraseña', value: 'password', type: 'text' }
    ],
    NewItemInputSelect: { role: [{ text: 'admin', value: 1 }, { text: 'Diseñador', value: 2 }] }
  }),

  computed: {
    ...mapState(['userToken', 'userDb'])
  },

  watch: {
    openNewItem (val) {
      val || this.close()
    },

    openItemDelete (val) {
      val || this.closeDelete()
    },

    textSearch (newValue) {
      const searchRegex = new RegExp(newValue, 'i')
      this.dataTable = this.allOrders.filter(event =>
        !newValue || searchRegex.test(event.name) || searchRegex.test(event.last_name) || searchRegex.test(event.email))
    }
  },

  created () {
    this.initialize()
  },

  methods: {
    ...mapActions(['signout', 'handleLoading', 'handleAlert']),

    setTableTitle () {
      return 'Lista de Usuarios'
    },

    setNewItemTitle () {
      return this.editedIndex === -1 ? 'Agregar Usuario' : 'Editar Usuario'
    },

    setBtnNewItemText () {
      return 'Nuevo'
    },

    async initialize () {
      this.handleLoading(true)
      await this.getRoles()
      await this.getUsers()
      this.handleLoading(false)
    },

    async getUsers () {
      try {
        this.handleLoading(true)
        const res = await getAllUsers(this.userToken)
        this.dataTable = this.formatDataTable(res.data)
      } catch (error) {
        this.handleMessageErrorApi(error)
      }
    },

    formatDataTable (data) {
      if (Array.isArray(data)) {
        data.forEach(e => {
          this.assignData(e)
        })
      } else {
        this.assignData(data)
      }
      return data
    },

    assignData (e) {
      e.role = e.roles[0].id
      e.profile = e.roles[0].name
    },

    setNewItemInputSelect (data, key, val = 'name') {
      const array = []
      data.forEach(e => {
        const obj = { text: e[val], value: e.id }
        array.push(obj)
      })
      this.NewItemInputSelect[key] = array
    },

    async getRoles () {
      try {
        const res = await getAllRoles(this.userToken)
        this.setNewItemInputSelect(res.data, 'role')
      } catch (error) {
        this.handleMessageErrorApi(error)
      }
    },

    handleItemDelete (value) {
      this.openItemDelete = value
    },

    handleNewItem (value) {
      this.openNewItem = value
    },

    editItem (item) {
      this.editedIndex = this.dataTable.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.openNewItem = true
    },

    deleteItem (item) {
      this.editedIndex = this.dataTable.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.openItemDelete = true
    },

    async deleteItemConfirm () {
      try {
        this.loadingDelete = true
        const res = await deleteUser(this.userToken, this.editedItem.id)
        this.handleMessageSuccessApi(res.data.message)
        this.dataTable.splice(this.editedIndex, 1)
        this.closeDelete()
      } catch (error) {
        this.handleMessageErrorApi(error)
      } finally {
        this.loadingDelete = false
      }
    },

    close () {
      this.openNewItem = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.openItemDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save () {
      if (this.editedIndex > -1) {
        await this.updateOne()
      } else {
        await this.createOne()
      }
    },

    async createOne () {
      try {
        this.loadingSave = true
        const res = await createUser(this.userToken, this.editedItem)
        this.handleMessageSuccessApi(res.data.message)
        this.dataTable.push(this.formatDataTable(res.data.user))
        this.close()
      } catch (error) {
        this.handleMessageErrorApi(error)
      } finally {
        this.loadingSave = false
      }
    },

    async updateOne () {
      try {
        this.loadingSave = true
        const res = await updateUser(this.userToken, this.editedItem)
        this.handleMessageSuccessApi(res.data.message)
        Object.assign(this.dataTable[this.editedIndex], this.formatDataTable(res.data.user))
        if (this.userDb.id === this.editedItem.id) {
          this.userDb.name = this.editedItem.name
          this.userDb.last_name = this.editedItem.last_name
          this.userDb.email = this.editedItem.email
        }
        this.close()
      } catch (error) {
        this.handleMessageErrorApi(error)
      } finally {
        this.loadingSave = false
      }
    },

    handleMessageErrorApi (error) {
      this.alertColor = 'error'
      if (error.status === 401) {
        this.signout()
        this.alertMessage = 'Sesión caducada'
      } else {
        if (error.status === 422) {
          const firstError = Object.keys(error.data.errors)[0]
          this.alertMessage = error.data.errors[firstError][0]
        } else {
          this.alertMessage = error.status
        }
      }
      this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
    },

    handleMessageSuccessApi (message) {
      this.alertColor = 'success'
      this.alertMessage = message
      this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
    },

    errorsValidationProps (value, item) {
      if (value === 'email') {
        if (!item.email) return true
      }

      if (value === 'password' && this.editedIndex > -1) {
        return false
      } else {
        if (!item.required) return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
