import axios from './instance'

const configToken = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export const getAllStatus = async (token) => {
  try {
    const res = await axios.get('/orders_status', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}
