<template>
  <div style="height: 59%; overflow-y: auto">
    <v-col>
      <v-row>
        <div v-for="n in etiquetas" :key="n" style="width: 32%">
          <img v-bind:src="getImgUrl(n)" @click="$emit('iconSelected', n)" />
        </div>
      </v-row>
    </v-col>
  </div>
</template>
<script>
export default {
  props: { etiqueta: String },

  data: () => ({
    etiquetas: ['ancla', 'arcoiris', 'ardilla', 'auto', 'bailarina-r', 'bailarina', 'barco', 'beba', 'bebe',
      'bici', 'buho-rosa', 'buho', 'calavera', 'camiseta-arg', 'cohete', 'corazon-multicolor', 'corazon',
      'corona', 'cupcake', 'dino-rex', 'dino-rojo', 'dino-verde', 'dona', 'emoji', 'estrellas-', 'flor-rosa',
      'flor', 'gato', 'hada', 'helado', 'heroina', 'leon', 'mapache', 'mariposa', 'mona', 'mono', 'monstruo-celeste',
      'monstruo', 'musica', 'nave', 'nena-colitas-m', 'nena-colitas', 'nena-guitarra', 'nene-argentina-m', 'nene-boca',
      'nene-boca-m', 'nene-estudiantes', 'nene-estudiantes-m', 'nene-guitarra', 'nene-independiente', 'nene-independiente-m',
      'nene-newells', 'nene-newells-m', 'nene-river', 'nene-river-m', 'nene-rugby', 'nene-rugby-m', 'nene-san-lorenzo',
      'nene-san-lorenzo-m-', 'nene-tigre', 'nene-tigre-m', 'nene-velez', 'nene-velez-m', 'no-azucar', 'no-carne', 'no-frutos-secos',
      'no-huevo', 'no-lacteos', 'no-pescado', 'no-tacc', 'olo-panda', 'oso', 'oveja', 'pajarito', 'pajaro-rulo', 'paz', 'pelota',
      'perro', 'personaje-camiseta-boca', 'personaje-camiseta-messi', 'pinguino-azul', 'pinguino-violeta', 'pirata', 'pirata-barba',
      'princesa', 'princesa-m', 'rayitas', 'robot', 'sandia', 'sapo', 'si-nombre', 'sirena', 'submarino', 'superheroe', 'tigre',
      'unicornio', 'unicornio-rostro', 'vaquita', 'yeah', 'zorro']
  }),
  methods: {
    getImgUrl (pet) {
      var images = require.context('@/assets/icons/etiquetas/', false, /\.png$/)
      return images('./' + pet + '.png')
    }
  }
}
</script>
