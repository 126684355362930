<template>
  <p
    class="mb-2 caption text--secondary"
  >
    {{title}}:
    <span class="caption text--primary"
    >{{commentary}}</span
    >
  </p>
</template>

<script>
export default {
  name: 'ExpandedItemComentary',
  props: {
    title: {
      type: String
    },
    commentary: {
      type: String
    }
  }
}
</script>

<style scoped lang="scss">
p {
  text-align: left;
  margin-bottom: 0;
}
</style>
