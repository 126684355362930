<template>
  <v-card
        elevation="0"
        class="mb-3 py-8"
    >
        <v-row style="height:100%">
          <v-col :cols="12/status.length" align-self="center" v-for="(state, index) in status" :key="state.id">
            <v-row justify="center" style="background-position: right;">
              <v-icon v-if="item.order_status.id < state.id" color="#9D9D9D">mdi-numeric-{{index}}-circle</v-icon>
              <v-icon v-else color="#ED008C">mdi-check-circle</v-icon>
            </v-row>
            <v-row justify="center">
              <strong v-if="state.id === 5 && stringCompare(item.shipping_line)" class="font-state">{{state.text}} para retirar 😍</strong>
              <strong v-else-if="state.id === 5 && !stringCompare(item.shipping_line)" class="font-state">{{state.text}} para despachar 😍</strong>
              <strong v-else class="font-state">{{state.text}}</strong>
            </v-row>
          </v-col>
        </v-row>
    </v-card>
</template>
<script>
export default {
  name: 'CardState',
  props: { item: Object },
  data: () => ({
    status: [
      { text: 'Pedido aprobado 👍', id: 3 },
      { text: '¡En produccion! 💪', id: 4 },
      { text: 'Pedido listo', id: 5 }
      // { text: 'Entregado 🎉', id: 6 }
    ]
  }),
  methods: {
    stringCompare (sentence) {
      return sentence.includes('Retiro oficina')
    }
  }
}
</script>
<style scoped lang="scss">
  .font_state {
    font-family: Roboto;
    font-size: 14px;
  }
  .v-icon.v-icon {
    font-size: 4rem;
  }
</style>
