<template>
  <v-dialog v-model="controlOpenDelete" max-width="500px">
    <v-card>
      <v-card-title class="headline">
        {{deleteItemTitle}}
      </v-card-title>

      <v-card-actions>
        <v-spacer></v-spacer>
         <v-btn
          v-for="(item, i) in btnDeleteItemProps"
          :key="i"
          :color="item.style"
          :text="item.bgOff"
          @click="$emit(item.event)"
          :loading="item.event === 'deleteItemConfirm' ? loading : false"
          >{{item.text}}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteItem',
  props: {
    openItemDelete: Boolean,
    loading: { type: Boolean, default: false },
    deleteItemTitle: { type: String, default: ' Seguro quieres borrar este item?' },
    btnDeleteItemProps: {
      type: Array,
      default () {
        return [
          { text: 'Cancelar', event: 'closeDelete', style: 'grey lighten-1', bgOff: true },
          { text: 'Borrar', event: 'deleteItemConfirm', style: 'primary', bgOff: false }
        ]
      }
    }
  },
  computed: {
    controlOpenDelete: {
      get () {
        return this.openItemDelete
      },
      set (value) {
        this.$emit('handleItemDelete', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
