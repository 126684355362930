<template>
  <div :class="$vuetify.breakpoint.xs ? 'mx-4 mt-4' : 'mx-8 mt-8'">
    <SalePreview v-if="editedItem!==null" :item="editedItem" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import SalePreview from '@/components/preview/SalePreview'
import { getOrder } from '@/services/orders.js'
export default {
  name: 'Preview',

  components: {
    SalePreview
  },

  created () {
    this.initialize()
  },

  data: () => ({
    editedItem: null
  }),

  methods: {
    ...mapActions(['handleLoading', 'handleAlert']),

    async initialize () {
      await this.getSale()
    },

    async getSale () {
      try {
        this.handleLoading(true)
        const order = this.$route.params.order
        const res = await getOrder(order)
        this.editedItem = res.data
      } catch (error) {
        this.handleMessageErrorApi(error)
      } finally {
        this.handleLoading(false)
      }
    },

    handleMessageErrorApi (error) {
      this.alertColor = 'error'
      if (error.status === 422) {
        const firstError = Object.keys(error.data.errors)[0]
        this.alertMessage = error.data.errors[firstError][0]
      } else {
        this.alertMessage = error.status
      }
      this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
    }
  }

}
</script>

<style scoped>
</style>
