<template>
  <div :class="$vuetify.breakpoint.xs ? 'mx-4 mt-4' : 'mx-8 mt-8'">
    <PersonalizeSale />
  </div>
</template>

<script>
import PersonalizeSale from '@/components/personalize/PersonalizeSale.vue'

export default {
  name: 'Personalize',

  components: {
    PersonalizeSale
  }
}
</script>

<style scoped>
</style>
