<template>
  <div>
    <div class="d-flex flex-column align-start px-10 py-8">
      <v-img width="56" height="56" src="@/assets/img/logo.png"></v-img>

      <p class="mb-0 mt-4 font-weight-bold text-h6">
        {{ userDb.name + " " + userDb.last_name }}
      </p>
      <p class="ma-0 text-caption text--secondary">{{ userDb.email }}</p>
    </div>
    <v-divider class="mb-2"></v-divider>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MenuNavHeader',

  data: () => ({
    //
  }),
  computed: {
    ...mapState(['userDb'])
  }
}
</script>

<style lang="scss" scoped>
</style>
