<template>
  <v-app id="app">
    <PersonalizeSale v-if="this.$route.params.id"/>
    <v-main v-else-if="userLogged">
      <Header v-if="!this.$route.params.order"/>
      <MenuNav />
      <router-view />
    </v-main>
    <Login v-else />
    <AppLoading />
    <AppAlert />
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Header from '@/components/layout/Header'
import MenuNav from '@/components/layout/MenuNav'
import AppLoading from '@/components/layout/loading/AppLoading'
import AppAlert from '@/components/layout/toast/AppAlert'
import Login from '@/views/Login'
import PersonalizeSale from '@/views/PersonalizeSale'

export default {
  name: 'App',
  components: {
    Header,
    MenuNav,
    Login,
    AppLoading,
    AppAlert,
    PersonalizeSale
  },

  data: () => ({}),

  created () {
    this.checkUserLogged()
  },
  computed: {
    ...mapGetters(['userLogged'])
  },

  methods: {
    ...mapActions(['checkUserLogged'])
  }

}
</script>

<style lang="scss" scoped>
#app {
  background-color: $bgBody;
}
</style>
