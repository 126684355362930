<template>
  <div>
    <v-col>
      <div class="">
        <div style="display:inline-flex; width:100%">
          <div :style="`height:39px; width:39px; background-color:${item.products_order[index] ? item.products_order[index].color : 'white'}`">

          </div>
          <div class="d-flex align-self-center text-left" style="width: 177px">
            <div style="width: 100%" class="pl-4">
              <strong style="font-size: 14px">Elegir Color</strong>
            </div>
          </div>
        </div>
        <v-col>
          <v-row>
            <div
              class="mt-2 mr-2"
              style="width: 17px; height: 17px; display:inline-flex"
              v-for="val in colors"
              :key="val"
            >
              <div
                :style="`width:100%;
                  height:100%;
                  cursor:pointer;
                  background-color:${val}`"
                @click="$emit('changeColor', val)"
              ></div>
            </div>
          </v-row>
        </v-col>
      </div>
      <div class="pt-2">
        <div
          class="pb-2 pt-2"
          cols="12"
          sm="12"
          v-for="(obj, i) in inputs"
          :key="i"
        >
          <v-text-field
            v-if="obj.type === 'text'"
            v-model="item.products_order[index][obj.value]"
            :placeholder="obj.text"
            :hide-details="true"
            height="40px"
            filled
            dense
          ></v-text-field>

          <v-textarea
            v-else
            v-model="item.products_order[index][obj.value]"
            :placeholder="obj.text"
            :hide-details="true"
            height="81px"
            filled
            dense
          >
          </v-textarea>
        </div>
      </div>
    </v-col>
  </div>
</template>
<script>
export default {
  name: 'TabEdit',
  props: { item: Object, index: Number },
  data: () => ({
    colors: ['#205C9F', '#0E9BDB', '#369443', '#cb2127', '#ca0087', '#d15a80', '#612e8c', '#685898'],
    inputs: [
      { text: 'Nombre', value: 'name', type: 'text' },
      { text: 'Apellido', value: 'last_name', type: 'text' },
      { text: 'Comentario', value: 'commentary', type: 'textarea' }
    ]
  })
}
</script>
