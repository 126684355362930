<template>
  <div :class="$vuetify.breakpoint.xs ? 'mx-4 mt-4' : 'mx-8 mt-8'">
    <UsersTable />
  </div>
</template>

<script>
import UsersTable from '@/components/users/UsersTable'

export default {
  name: 'UserPanel',
  components: { UsersTable },
  data: () => ({
    //
  })
}
</script>
